/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .grid-camping-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-camping-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-camping-camp-sam-hatcher {
    grid-area: 2 / 1 / 4 / 9;
  }

  .grid-camping-reservations {
    grid-area: 2 / 9 / 3 / 13;
  }

  .grid-camping-camp-boddie {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-camping-pamlico-sea-base {
    grid-area: 5 / 1 / 6 / 13;
  }

  .grid-camping-potashcorp-eco-lodge {
    grid-area: 6 / 1 / 7 / 13;
  }
}
