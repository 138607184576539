/* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: none;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: none;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: none;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: none;
  }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: block;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .grid-findaunit-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-findaunit-header-image {
    grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-join-today {
    grid-area: 2 / 1 / 3 / 13;
  }

  .data-grid-1 {
    grid-area: 3 / 1 / 4 / 13;
  }

  .data-grid-2 {
    grid-area: 4 / 1 / 5 / 13;
  }

  .data-grid-3 {
    grid-area: 5 / 1 / 6 / 13;
  }

  .data-grid-4 {
    grid-area: 6 / 1 / 7 / 13;
  }

  .data-grid-5 {
    grid-area: 7 / 1 / 8 / 13;
  }

  .data-grid-6 {
    grid-area: 8 / 1 / 9 / 13;
  }

  #hideSpan {
    display: block;
  }
}
