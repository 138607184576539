/* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;
  }

  .grid-body {
    grid-area: 1 / 2 / 1 / 12;
  }
}

/* 
##Device = Laptops, Desktops
##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;
  }

  .grid-body {
    grid-area: 1 / 2 / 1 / 12;
  }
}

/* 
##Device = Tablets, Ipads (portrait)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;
  }

  .grid-body {
    grid-area: 1 / 2 / 1 / 12;
  }
}

/* 
##Device = Tablets, Ipads (landscape)
##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;
  }

  .grid-body {
    grid-area: 1 / 1 / 1 / 13;
  }
}

/* 
##Device = Low Resolution Tablets, Mobiles (Landscape)
##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .MuiTabs-centered a .MuiTab-wrapper {
    font-size: 10px;
  }

  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;

    .grid-body {
      grid-area: 1 / 1 / 1 / 13;
    }
  }

  #logo-container {
    overflow: hidden;
    width: 65px;
  }

  #copyright {
    font-size: 10px;
  }

  #webmaster {
    font-size: 10px;
  }
}

/* 
##Device = Most of the Smartphones Mobiles (Portrait)
##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .MuiTabs-centered a .MuiTab-wrapper {
    font-size: 10px;
  }

  .grid-site-container {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 0rem;

    .grid-body {
      grid-area: 1 / 1 / 1 / 13;
    }
  }

  #logo-container {
    overflow: hidden;
    width: 65px;
  }

  #copyright {
    font-size: 10px;
  }

  #webmaster {
    font-size: 10px;
  }
}
