.contact-cards {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: flex-start;

  .contact-cards-card {
    margin: 0.5rem;
    width: 370px;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-downloads {
      grid-area: 3 / 1 / 4 / 4;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 4 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-downloads {
      grid-area: 3 / 1 / 4 / 4;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 4 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-downloads {
      grid-area: 3 / 1 / 4 / 4;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 4 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-downloads {
      grid-area: 3 / 1 / 4 / 6;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 6 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 1 / 4 / 13;
    }

    .grid-resources-downloads {
      grid-area: 4 / 1 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .grid-resources-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;

    .grid-resources-header-image {
      grid-area: 1 / 1 / 2 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
    }

    .grid-resources-about {
      grid-area: 2 / 1 / 3 / 13;
    }

    .grid-resources-expansion-panels-1 {
      grid-area: 3 / 1 / 4 / 13;
    }

    .grid-resources-downloads {
      grid-area: 4 / 1 / 5 / 13;
    }

    .grid-resources-district-contacts {
      grid-area: 5 / 1 / 6 / 13;
    }
  }
}
