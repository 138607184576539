/* 
##Device = Desktops
##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 2 / 10;
  }

  .grid-home-download-now {
    grid-area: 2 / 10 / 2 / 13;
  }

  .grid-card-1 {
    grid-area: 3 / 1 / 3 / 5;
  }

  .grid-card-2 {
    grid-area: 3 / 5 / 3 / 9;
  }

  .grid-card-3 {
    grid-area: 3 / 9 / 3 / 13;
  }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 2 / 10;
  }

  .grid-home-download-now {
    grid-area: 2 / 10 / 2 / 13;
  }

  .grid-card-1 {
    grid-area: 3 / 1 / 3 / 5;
  }

  .grid-card-2 {
    grid-area: 3 / 5 / 3 / 9;
  }

  .grid-card-3 {
    grid-area: 3 / 9 / 3 / 13;
  }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 2 / 10;
  }

  .grid-home-download-now {
    grid-area: 2 / 10 / 2 / 13;
  }

  .grid-card-1 {
    grid-area: 3 / 1 / 3 / 5;
  }

  .grid-card-2 {
    grid-area: 3 / 5 / 3 / 9;
  }

  .grid-card-3 {
    grid-area: 3 / 9 / 3 / 13;
  }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 3 / 8;
  }

  .grid-home-download-now {
    grid-area: 2 / 8 / 3 / 13;
  }

  .grid-card-1 {
    grid-area: 4 / 1 / 5 / 5;
  }

  .grid-card-2 {
    grid-area: 4 / 5 / 5 / 9;
  }

  .grid-card-3 {
    grid-area: 4 / 9 / 5 / 13;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 2 / 13;
  }

  .grid-card-1 {
    grid-area: 3 / 1 / 3 / 13;
  }

  .grid-card-2 {
    grid-area: 4 / 1 / 4 / 13;
  }

  .grid-card-3 {
    grid-area: 5 / 1 / 5 / 13;
  }

  .grid-home-download-now {
    grid-area: 6 / 1 / 6 / 13;
  }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .grid-home-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-home-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-body-1 {
    grid-area: 2 / 1 / 2 / 13;
  }

  .grid-card-1 {
    grid-area: 3 / 1 / 3 / 13;
  }

  .grid-card-2 {
    grid-area: 4 / 1 / 4 / 13;
  }

  .grid-card-3 {
    grid-area: 5 / 1 / 5 / 13;
  }

  .grid-home-download-now {
    grid-area: 6 / 1 / 6 / 13;
  }
}
