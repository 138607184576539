/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-event-bar {
    grid-area: 2 / 1 / 7 / 4;
  }

  .grid-calendar {
    grid-area: 2 / 4 / 5 / 13;
  }

  .grid-add-calendar {
    grid-area: 5 / 4 / 5 / 10;
  }

  .grid-download-now {
    grid-area: 5 / 10 / 5 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: auto;
    text-align: unset;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: unset;
    padding-bottom: unset;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-event-bar {
    grid-area: 2 / 1 / 7 / 4;
  }

  .grid-calendar {
    grid-area: 2 / 4 / 5 / 13;
  }

  .grid-add-calendar {
    grid-area: 5 / 4 / 5 / 10;
  }

  .grid-download-now {
    grid-area: 5 / 10 / 5 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: auto;
    text-align: unset;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: unset;
    padding-bottom: unset;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-event-bar {
    grid-area: 2 / 1 / 7 / 4;
  }

  .grid-calendar {
    grid-area: 2 / 4 / 5 / 13;
  }

  .grid-add-calendar {
    grid-area: 5 / 4 / 5 / 10;
  }

  .grid-download-now {
    grid-area: 5 / 10 / 5 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: auto;
    text-align: unset;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: unset;
    padding-bottom: unset;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-calendar {
    grid-area: 2 / 1 / 3 / 13;
  }

  .grid-event-bar {
    grid-area: 3 / 1 / 4 / 8;
  }

  .grid-download-now {
    grid-area: 3 / 8 / 4 / 13;
  }

  .grid-add-calendar {
    grid-area: 4 / 1 / 5 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: auto;
    text-align: unset;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: unset;
    padding-bottom: unset;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-calendar {
    grid-area: 2 / 1 / 3 / 13;
  }

  .grid-event-bar {
    grid-area: 3 / 1 / 4 / 13;
  }

  .grid-add-calendar {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-download-now {
    grid-area: 5 / 1 / 6 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: 100%;
    text-align: center;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .grid-calendar-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-calendar-header-image {
    grid-area: 1 / 1 / 1 / 13; //grid-row-start / grid-column-start / grid-row-end / grid-column-end
  }

  .grid-calendar {
    grid-area: 2 / 1 / 3 / 13;
  }

  .grid-event-bar {
    grid-area: 3 / 1 / 4 / 13;
  }

  .grid-add-calendar {
    grid-area: 4 / 1 / 5 / 13;
  }

  .grid-download-now {
    grid-area: 5 / 1 / 6 / 13;
  }

  .rbc-toolbar .rbc-btn-group {
    width: 100%;
    text-align: center;
  }

  .rbc-toolbar .rbc-toolbar-label {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
